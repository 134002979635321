import { ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { VerticalCarousel } from '../catalogs/VerticalCarousel';
import { AccordionCompetitor } from '../../tournaments/participants/accordion-competitor/AccordionCompetitor';
import { teamsParser } from '../../../util/tournaments/teamParser';
import { Team } from '../../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import {
  PARTICIPANT_DIMENSIONS_MOBILE,
  PARTICIPANT_DIMENSIONS,
} from '../../../../functions/src/config/ads/adDimensions';
import { renderHits } from '../../../util/algolia/renderHits';
import { useMobile } from '../../../hooks/useMobile';

export type TeamsCarouselWrapperProps = {
  hits: OrNode<Hit<Team<Date>>>[];
  onLoadMore: () => void;
  Header: ReactNode;
  maxTeamSize: number;
};

const TeamsCarouselWrapperUnmemoized = ({
  hits,
  onLoadMore,
  Header,
  maxTeamSize,
}: TeamsCarouselWrapperProps) => {
  const Participants = useMemo(() => {
    return renderHits(hits, (hit) => {
      const props = teamsParser(hit, maxTeamSize);
      return <AccordionCompetitor {...props} />;
    });
  }, [hits, maxTeamSize]);

  return (
    <VerticalCarousel
      Header={Header}
      Content={Participants}
      onNearEnd={onLoadMore}
      buffer={4}
      share={false}
      showDivider={false}
      background={8}
      containerSx={{ p: 4 }}
    />
  );
};

export const TeamsCarouselWrapper = memo(TeamsCarouselWrapperUnmemoized);

export const TEAM_CAROUSEL_AD_INTERVAL = 20 as const;

export const useTeamsCarouselWrapperAds = (containerId: string) => {
  const isMobile = useMobile();

  const participantDimensions = isMobile
    ? PARTICIPANT_DIMENSIONS_MOBILE
    : PARTICIPANT_DIMENSIONS;

  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: TeamsCarouselWrapper,
      adInterval: TEAM_CAROUSEL_AD_INTERVAL,
      width: participantDimensions.width,
      height: participantDimensions.height,
      baseContainerId: containerId,
      adjustable: participantDimensions.adjustable,
    });
  }, [
    containerId,
    participantDimensions.adjustable,
    participantDimensions.height,
    participantDimensions.width,
  ]);
};
