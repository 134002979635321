import { USER_CONFIGURE_OPTIONS } from '../../../../../functions/src/util/algolia/config/user';
import { memo } from '../../../../util/memo';
import { AlgoliaLayout } from '../../../algolia/AlgoliaLayout';
import { useUserDropdownSearchAds } from '../../../algolia/catalog-wrappers/UserDropdownSearch';
import { REGISTRATION_AD } from '../../../../../functions/src/util/ads/adConstants';
import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';

export const TEAMMATE_HITS_PER_PAGE = 10;
export const ADD_TEAMMATE_AUTOCOMPLETE_AD_INTERVAL = 4;
export const ADD_TEAMMATE_AUTOCOMPLETE_AD_BORDER_RADIUS = '10px';

const TEAMMATE_CONFIGURE_OPTIONS = {
  ...USER_CONFIGURE_OPTIONS,
  hitsPerPage: TEAMMATE_HITS_PER_PAGE,
};

const AddTeammateAutocompleteUnmemoized = () => {
  const { id: tournamentId } = useTournamentRegistration();

  const UserDropdownSearchAds = useUserDropdownSearchAds(
    `${REGISTRATION_AD}-${tournamentId}`,
  );

  return (
    <AlgoliaLayout
      CatalogWrapper={UserDropdownSearchAds}
      configureOptions={TEAMMATE_CONFIGURE_OPTIONS}
      index="CONTENT"
    />
  );
};

export const AddTeammateAutocomplete = memo(AddTeammateAutocompleteUnmemoized);
