import Box from '@mui/material/Box';
import { ParticipantFill } from 'src/components/tournaments/participants/ParticipantFill';
import { useContext, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { teamsParser } from '../../../util/tournaments/teamParser';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import {
  TeamsCarouselWrapperProps,
  useTeamsCarouselWrapperAds,
} from '../../algolia/catalog-wrappers/TeamsCarouselWrapper';
import { TeamsCarouselHeader } from '../../algolia/headers/TeamsCarouselHeader';
import { PHASES_WITH_PARTICIPANTS_ONLY } from '../../../contexts/TournamentRegistrationContext';
import { SwitchPanelAlgolia } from '../../algolia/filters/SwitchPanelAlgolia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { teamFilters } from '../../../../functions/src/util/algolia/config/team';
import { PARTICIPANTS_AD } from '../../../../functions/src/util/ads/adConstants';
import { TEAM_INDEX_NAME } from '../../../../functions/src/util/algolia/indexNames';

const ParticipantsPaneUnmemoized = () => {
  const {
    id,
    phase,
    maxTeamSize,
    maxTeamCount,
    guestlistAggregated,
    waitlistAggregated,
    participantsAggregated,
  } = useContext(TournamentContext);

  const isOnlyParticipants = PHASES_WITH_PARTICIPANTS_ONLY.includes(phase);
  const registrationlistAggregated = (
    isOnlyParticipants ? participantsAggregated : guestlistAggregated
  ).map((team) => {
    return teamsParser(team, maxTeamSize);
  });
  const registeredTotal: number = registrationlistAggregated
    .map((entry) => {
      return entry.currentTeamFill;
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const waitlist = isOnlyParticipants
    ? []
    : waitlistAggregated.map((team) => {
        return teamsParser(team, maxTeamSize);
      });
  const waitlistTotal: number = waitlist
    .map((entry) => {
      return entry.currentTeamFill;
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const [isWaitlistActive, setIsWaitlistActive] = useState(false);

  const handleWaitlistToggle = useCallback(() => {
    setIsWaitlistActive((prevState) => {
      return !prevState;
    });
  }, [setIsWaitlistActive]);

  const WaitlistSwitch = useMemo(() => {
    return (
      <SwitchPanelAlgolia
        title={
          <>
            <Typography
              variant="h6"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                pr: 2,
              }}
            >
              WAITLIST
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {waitlistTotal}
            </Typography>
          </>
        }
        onToggle={handleWaitlistToggle}
        checked={isWaitlistActive}
      />
    );
  }, [waitlistTotal, handleWaitlistToggle, isWaitlistActive]);

  const filter = useMemo(() => {
    return teamFilters(phase, id, isWaitlistActive);
  }, [isWaitlistActive, id, phase]);

  const Title = useMemo(() => {
    return (
      <ParticipantFill
        currentFill={registeredTotal}
        maxFill={maxTeamSize * maxTeamCount}
      />
    );
  }, [registeredTotal, maxTeamCount, maxTeamSize]);

  const TeamsCarouselWrapperAds = useTeamsCarouselWrapperAds(
    `${PARTICIPANTS_AD}-${id}`,
  );

  const ParticipantsCatalogWrapper = useCallback(
    (props: Omit<TeamsCarouselWrapperProps, 'Header' | 'maxTeamSize'>) => {
      return (
        <TeamsCarouselWrapperAds
          {...props}
          Header={
            <TeamsCarouselHeader Title={Title}>
              {WaitlistSwitch}
            </TeamsCarouselHeader>
          }
          maxTeamSize={maxTeamSize}
        />
      );
    },
    [TeamsCarouselWrapperAds, Title, WaitlistSwitch, maxTeamSize],
  );

  const theme = useTheme();

  return (
    <Box
      sx={{
        px: { xs: 4 },
        minWidth: { xs: '100%', lg: '640px' },
        height: '100%',
        maxHeight: '846px',
        overflowY: 'scroll',
        alignSelf: 'center',
        ...theme.palette.scrollbars.invisible,
      }}
    >
      <AlgoliaLayout
        CatalogWrapper={ParticipantsCatalogWrapper}
        configureOptions={{
          filters: filter,
          hitsPerPage: 500,
        }}
        index={TEAM_INDEX_NAME}
      />
    </Box>
  );
};
export const ParticipantsPane = memo(ParticipantsPaneUnmemoized);
