import Box from '@mui/material/Box';
import { useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { GradientTypography } from '../gradients/GradientTypography';
import { LiveBadge } from '../LiveBadge';
import { TournamentPayout } from '../cards/tournament/TournamentPayout';
import Stack from '@mui/material/Stack';
import { TournamentLivestreamButton } from '../livestream/mux/TournamentLivestreamButton';
import { useAuth } from '../../contexts/AuthContext';
import { isStreamer } from '../../util/roles/isStreamer';
import { TournamentViewStreamButton } from '../livestream/mux/TournamentViewStreamButton';
import { useTheme } from '@mui/material/styles';
import { extractLiveOf } from '../../../functions/src/util/mux/extractLiveOf';

function TournamentTitleUnmemoized() {
  const theme = useTheme();
  const { uid } = useAuth();
  const { title, date, phase, id, roles, livestreamId, playbackIds } =
    useContext(TournamentContext);
  const formatDate = useDateFormatter();
  const dateFormatted = formatDate(date);
  const canStream = isStreamer(roles, uid || undefined);
  const playbackIdLatest = extractLiveOf(playbackIds);

  const summary = `${title} ${dateFormatted}`;

  const tournamentLivestreamButton = useMemo(() => {
    return !!canStream && <TournamentLivestreamButton tournamentId={id} />;
  }, [canStream, id]);

  const viewerCountButton = useMemo(() => {
    return (
      !!playbackIdLatest &&
      !!livestreamId && (
        <TournamentViewStreamButton
          tournamentId={id}
          playbackId={playbackIdLatest}
          livestreamId={livestreamId}
        />
      )
    );
  }, [playbackIdLatest, livestreamId, id]);

  const endComponent = useMemo(() => {
    return canStream ? tournamentLivestreamButton : viewerCountButton;
  }, [canStream, tournamentLivestreamButton, viewerCountButton]);

  return (
    <Stack spacing={1} alignItems="center">
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {phase === 'live' && (
          <LiveBadge
            sx={{ mr: 2, mb: '-2px', background: theme.palette.primary.main }}
          />
        )}
        <TournamentPayout variant="h5" sx={{ fontWeight: '700' }} />
        <GradientTypography
          variant="h5"
          gradientColor={'primary.vertical'}
          component="div"
          textTransform={'uppercase'}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {summary}
        </GradientTypography>
        <Box sx={{ pl: 3 }}>{endComponent}</Box>
      </Stack>
    </Stack>
  );
}

export const TournamentTitle = memo(TournamentTitleUnmemoized);
