import { useMemo, useEffect } from 'react';
import { useRealtimeChannelGroups } from '../../contexts/RealtimeChannelGroupContext';
import { useMobile } from '../useMobile';
import { useActiveTournamentChannel } from './useActiveTournamentChannel';
import { useUnreadMessageCount } from '../../contexts/UnreadMessageCountContext';
import { isTournament } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isTournament';

export const useOpenGeneralChannelOnLoad = (tournamentId: string) => {
  const { openGeneralChannel } = useActiveTournamentChannel(tournamentId);
  const { realtimeChannelGroups } = useRealtimeChannelGroups();
  const { unreadMessageCount } = useUnreadMessageCount();

  const isChannelGroupLoaded = useMemo(() => {
    const tournamentChannelGroup = realtimeChannelGroups.find(
      (channelGroup) => {
        const { groupFilter } = channelGroup;
        return groupFilter[0].tournamentId === tournamentId;
      },
    );
    if (!tournamentChannelGroup) {
      return false;
    }
    // The reason we check !!unreadMessageCount[id as string] is because we need to wait
    // until the UnreadMessageCountContext has fully loaded the channel group to prevent
    // other errors being thrown.
    return (
      !!unreadMessageCount[tournamentChannelGroup.id as string] &&
      isTournament(tournamentChannelGroup)
    );
  }, [realtimeChannelGroups, tournamentId, unreadMessageCount]);

  const isMobile = useMobile();

  useEffect(() => {
    if (isMobile || !isChannelGroupLoaded) {
      return;
    }
    openGeneralChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isChannelGroupLoaded]);
};
