import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { memo } from '../../../util/memo';
import { FC, Fragment, useContext, useMemo } from 'react';
import { EVENT_TYPE_TO_EDITABLE_FIELDS } from 'src/contexts/organizer/create-event/constants';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { PhaseStepper } from '../PhaseStepper';
import { EventTime } from '../../leaderboards/EventTime';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { GradientTypography } from '../../gradients/GradientTypography';
import { CheckInOption } from '../../organizer/CheckInOption';
import { AccordionInfo } from '../../AccordionInfo';
import {
  ACCORDION_TITLE_LIVESTREAM,
  AccordionBodyLivestream,
} from '../../livestream/AccordionBodyLivestream';
import { PersonaAdContainer } from '../../ads/PersonaAdContainer';
import { OVERVIEW_PAGE_DIMENSIONS_MOBILE } from '../../../../functions/src/config/ads/adDimensions';
import { OVERVIEW_AD } from '../../../../functions/src/util/ads/adConstants';
import Box from '@mui/material/Box';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { AD_BORDER_RADIUS } from '../../algolia/catalog-wrappers/GameHorizontalCarousel';
import { useCountdownDisplay } from '../../../hooks/tournaments/useCountdownDisplay';

export const OVERVIEW_MOBLE_THRESHOLD_PX = 1470;

export type EditableTournamentKeys =
  typeof EVENT_TYPE_TO_EDITABLE_FIELDS.Tournament;

export type DisplayedTournamentKeys =
  | 'payoutDistributionType'
  | 'registrationOptions';

export type TournamentOverviewLayoutProps = {
  components: Record<
    EditableTournamentKeys[number] | DisplayedTournamentKeys,
    JSX.Element
  >;
  isEditing?: boolean;
};

const TournamentOverviewLayoutUnmemoized: FC<TournamentOverviewLayoutProps> = ({
  components,
  isEditing = false,
}) => {
  const {
    id: tournamentId,
    skipCheckIn: skipCheckInValue,
    title: tournamentTitle,
    phase,
  } = useContext(TournamentContext);
  const { date: eventTimeDate, title: eventTimeTitle } = useCountdownDisplay();
  const {
    title,
    date,
    endDate,
    teamSizeRange,
    maxTeamCount,
    region,
    bracketType,
    sponsors,
    tournamentDetails,
    registrationOptions,
    skipCheckIn,
    squareImgUrl,
    tournamentThumbnailImgUrl,
    checkInDuration,
    visibility,
  } = components;
  const registrationEditable = useMemo(() => {
    return phase === 'registration' && isEditing;
  }, [isEditing, phase]);

  const updatedContainerId = `${OVERVIEW_AD}-${tournamentId}`;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Stack
        gap={3}
        justifyContent="center"
        alignItems={'center'}
        sx={{
          maxWidth: {
            lg: '1000px',
          },
          [`@media (max-width: ${OVERVIEW_MOBLE_THRESHOLD_PX}px)`]: {
            maxWidth: '554px',
          },
        }}
      >
        <Stack direction={'row'} gap={3} alignItems={'center'}>
          {squareImgUrl}
          {tournamentThumbnailImgUrl}
        </Stack>

        {!registrationEditable && (
          <Stack direction="row" alignSelf="center" width={'100%'}>
            {title}
          </Stack>
        )}
        <PhaseStepper activePhase={phase} skipCheckIn={skipCheckInValue} />
        <EventTime date={eventTimeDate} title={eventTimeTitle} />

        <Stack
          direction="column"
          gap={3}
          sx={{ maxWidth: { xs: '100%', lg: 'none' } }}
        >
          <Stack direction="row" gap={3}>
            <Stack direction="column" gap={3} width="100%">
              <Stack spacing={3}>
                {!registrationEditable && (
                  <>
                    <TournamentPanelV3
                      title={
                        <GradientTypography
                          variant="h6"
                          fontWeight={600}
                          fontSize={18}
                          gradientColor="primary.vertical"
                          textTransform="uppercase"
                        >
                          Dates
                        </GradientTypography>
                      }
                      TitleIconComponent={DateRangeIcon}
                      Content={
                        <Stack gap={1}>
                          <CheckInOption
                            SkipCheckInComponent={skipCheckIn}
                            CheckInDuration={checkInDuration}
                            skipCheckIn={skipCheckInValue}
                          />
                          {date}
                          {endDate}
                        </Stack>
                      }
                    />
                    {isEditing ? (
                      <Stack spacing={3}>
                        {teamSizeRange}
                        <Stack spacing={3} direction={'row'}>
                          {maxTeamCount}
                          {visibility}
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack>
                        <Grid container spacing={3}>
                          {[teamSizeRange, maxTeamCount].map(
                            (component, index, arr) => {
                              return (
                                <Grid
                                  key={`${index}`}
                                  item
                                  xs={
                                    index === arr.length - 1 && index % 2 === 0
                                      ? 12
                                      : 6
                                  }
                                >
                                  {component}
                                </Grid>
                              );
                            },
                          )}
                        </Grid>
                      </Stack>
                    )}
                    <Stack>
                      <Grid container spacing={3}>
                        {[region, bracketType].map((component, index, arr) => {
                          return (
                            <Grid
                              key={`${index}`}
                              item
                              xs={
                                index === arr.length - 1 && index % 2 === 0
                                  ? 12
                                  : 6
                              }
                            >
                              {component}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Stack>
                  </>
                )}
                {!!sponsors && sponsors}
                <Stack justifyContent={'center'} alignItems={'center'}>
                  <PersonaAdContainer
                    baseContainerId={updatedContainerId}
                    width={OVERVIEW_PAGE_DIMENSIONS_MOBILE.width}
                    height={OVERVIEW_PAGE_DIMENSIONS_MOBILE.height}
                    adjustable={OVERVIEW_PAGE_DIMENSIONS_MOBILE.adjustable}
                    borderRadius={AD_BORDER_RADIUS}
                  />
                </Stack>
              </Stack>
              <AccordionInfo
                title={ACCORDION_TITLE_LIVESTREAM}
                body={
                  <AccordionBodyLivestream tournamentTitle={tournamentTitle} />
                }
              />
            </Stack>
          </Stack>
        </Stack>
        {tournamentDetails}
        {registrationOptions}
      </Stack>
    </Box>
  );
};

export const TournamentOverviewLayout = memo(
  TournamentOverviewLayoutUnmemoized,
);
