import { useCallback } from 'react';
import { useFindChannelId } from './useFindChannelId';
import { useFindChannelGroup } from './useFindChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';

export const useActiveTournamentChannel = (tournamentId: string) => {
  const { findByTournamentId } = useFindChannelGroup();
  const { findGeneralChannelId, findMatchChannelId } = useFindChannelId();
  const { openChannelGroup } = useActiveChannelGroup();

  const openGeneralChannel = useCallback(() => {
    const channelGroup = findByTournamentId(tournamentId);

    if (!channelGroup) {
      return;
    }

    const generalChannelId = findGeneralChannelId(tournamentId);

    openChannelGroup(channelGroup.id, generalChannelId);
  }, [
    findByTournamentId,
    findGeneralChannelId,
    openChannelGroup,
    tournamentId,
  ]);

  const openMatchChannel = useCallback(
    (matchId: string) => {
      const channelGroup = findByTournamentId(tournamentId);
      if (!channelGroup) {
        return;
      }
      const matchChannelId = findMatchChannelId(matchId);
      if (!matchChannelId) {
        return;
      }
      openChannelGroup(channelGroup.id, matchChannelId);
    },
    [findByTournamentId, findMatchChannelId, openChannelGroup, tournamentId],
  );

  return {
    openGeneralChannel,
    openMatchChannel,
  };
};
