import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { User } from '../../../../functions/src/types/firestore/User';
import { SearchDropdown } from '../catalogs/SearchDropdown';
import { AddTeammateListItem } from '../../cards/tournament/AddTeammateListItem';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { USER_REGISTRATION_DIMENSIONS } from '../../../../functions/src/config/ads/adDimensions';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { renderHits } from '../../../util/algolia/renderHits';

const BUFFER_BEFORE_FETCH = 4;

export type UserDropdownSearchProps = {
  hits: OrNode<Hit<User<Date>>>[];
  onLoadMore: () => void;
};

const UserDropdownSearchUnmemoized = ({
  hits,
  onLoadMore,
}: UserDropdownSearchProps) => {
  const Users = useMemo(() => {
    return renderHits(hits, (hit) => {
      return <AddTeammateListItem {...hit} />;
    });
  }, [hits]);

  return (
    <SearchDropdown
      Content={Users}
      onNearEnd={onLoadMore}
      buffer={BUFFER_BEFORE_FETCH}
      placeholder={'Search for a teammate...'}
    />
  );
};

export const UserDropdownSearch = memo(UserDropdownSearchUnmemoized);

export const USER_DROPDOWN_AD_INTERVAL = 20 as const;
export const USER_DROPDOWN_AD_BORDER_RADIUS = '10px';

export const useUserDropdownSearchAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: UserDropdownSearch,
      adInterval: USER_DROPDOWN_AD_INTERVAL,
      width: USER_REGISTRATION_DIMENSIONS.width,
      height: USER_REGISTRATION_DIMENSIONS.height,
      baseContainerId: containerId,
      adjustable: USER_REGISTRATION_DIMENSIONS.adjustable,
      borderRadius: USER_DROPDOWN_AD_BORDER_RADIUS,
    });
  }, [containerId]);
};
