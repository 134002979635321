import Stack from '@mui/material/Stack';
import Head from 'next/head';
import { ExpandableEventsCalendar } from '../../components/calendar/selectable-event/ExpandableEventsCalendar';
import { REVALIDATE_SECONDS } from '../../util/vercel/revalidateSeconds';
import { GetStaticPaths, GetStaticProps } from 'next';
import { withUtcParam } from '../../util/dates/withUtcParam';

export const PUBLISHED_ONLY_TOURNAMENT_CONFIG = `(NOT phase:unpublished) OR (phase:finished AND visibility:unlisted)`;

const HomePage = () => {
  return (
    <>
      <Head>
        <title>Home - BluMint</title>
      </Head>
      <Stack sx={{ px: { lg: 3, xs: 1 }, pt: 3 }}>
        {/* <Box sx={{ display: { xs: 'none', lg: 'block' } }}> */}
        {/* {!isMobile && (
            <AlgoliaLayout
              CatalogWrapper={VideoBannerWrapper}
              configureOptions={{
                filters: DEFAULT_VIDEO_BANNER_CONFIG,
              }}
              index="CONTENT"
            />
          )} */}
        {/* </Box> */}
        <ExpandableEventsCalendar calendarHeight="min(780px, max(520px, calc(100dvh - 364px)))" />
      </Stack>
    </>
  );
};

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
    revalidate: REVALIDATE_SECONDS,
  };
};

export const getStaticPaths: GetStaticPaths = withUtcParam(() => {
  return { paths: [], fallback: 'blocking' };
});

export { HomePage as default };
